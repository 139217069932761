<template>
  <div class="partyMemberForm">
    <form-panel
      ref="formPanel"
      v-bind="submitConfig"
      :form="form"
      :submitBefore="submitBefore"
      :submitSuccess="submitSuccess"
      @update="update"
    >
      <el-form-item label="姓名" prop="name">
        <v-input placeholder="请输入姓名" v-model="form.name"></v-input>
      </el-form-item>
      <el-form-item
        label="手机号"
        :rules="[
          { required: true, message: '请输入手机号', trigger: 'change' },
          { validator: validatePhone, trigger: 'change' },
        ]"
        prop="mobileNum"
      >
        <div class="form-item">
          <v-input
            :disabled="isEdit"
            placeholder="请输入手机号"
            v-model="form.mobileNum"
            @blur="handelInputBlur"
          ></v-input>
          <v-button
            style="margin-left: 10px"
            text="编辑"
            @click="handelClick"
            v-if="form.id"
          ></v-button>
        </div>
      </el-form-item>
      <el-form-item
        label="图片"
        :rules="[
          {
            required: true,
            message: '请上传图片',
            trigger: ['change', 'blur'],
          },
        ]"
        prop="avatarUrl"
      >
        <v-upload :limit="1" :imgUrls.sync="form.avatarUrl" />
      </el-form-item>
      <el-form-item
        label="是否党员"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isPartyMember"
      >
        <v-radio
          :radioObj="isPartyRadioObj"
          v-model="form.isPartyMember"
          @change="change"
        ></v-radio>
      </el-form-item>
      <el-form-item
        label="是否干部"
        :rules="[{ required: true, message: '请选择', trigger: 'blur' }]"
        prop="isPartyMember"
      >
        <v-radio :radioObj="isCadreRadioObj" v-model="form.isCadre"></v-radio>
      </el-form-item>
      <el-form-item
        label="排序"
        :rules="[{ required: true, message: '请输入排序值', trigger: 'blur' }]"
        prop="sortIndex"
      >
        <v-input
          placeholder="请输入排序值"
          v-model="form.sortIndex"
          :width="250"
          type="number"
        ></v-input>
      </el-form-item>
      <el-form-item label="职务" prop="post">
        <v-input placeholder="请输入职务" v-model="form.post"></v-input>
      </el-form-item>
      <el-form-item label="岗位职责" prop="dutyResp">
        <v-input placeholder="请输入岗位职责" v-model="form.dutyResp"></v-input>
      </el-form-item>
      <el-form-item label="网格管理" prop="respArea">
        <v-input placeholder="请输入网格管理" v-model="form.respArea"></v-input>
      </el-form-item>
    </form-panel>
  </div>
</template>

<script>
import { setAESCbcEncrypt } from "@/utils/common";
import { mobileRegular } from "@/utils/regular";
import {
  partyMemberDetailUrl,
  editPartyMemberUrl,
  cpPhoneEdit,
} from "./api.js";
import {} from "./map.js";
export default {
  name: "partyMemberForm",
  data() {
    return {
      width: 250,
      submitConfig: {
        queryUrl: partyMemberDetailUrl,
        submitUrl: editPartyMemberUrl,
      },
      isPartyRadioObj: [
        { name: "1", value: "是" },
        { name: "2", value: "否" },
      ],
      isCadreRadioObj: [
        { name: "1", value: "是" },
        { name: "2", value: "否" },
      ],
      form: {
        id: "",
        name: "",
        mobileNum: "",
        avatarUrl: "",
        isPartyMember: "2",
        isCadre: "2",
        post: "",
        dutyResp: "",
        respArea: "",
        sortIndex: 0,
      },
      isEdit: false,
    };
  },
  components: {},
  mounted() {
    const { id } = this.$route.query;
    this.form.id = id;
    if (id !== undefined) {
      this.isEdit = true;
      this.$refs.formPanel.getData({ id: id });
      this.handleRoomUrl();
    }
    this.$setBreadList(id ? "编辑" : "新增");
  },
  methods: {
    handelInputBlur() {
      if (this.form.id) {
        const params = {
          id: this.form.id,
          mobileNum: setAESCbcEncrypt(this.form.mobileNum),
        };
        this.$axios.post(cpPhoneEdit, params).then((res) => {
          if (res.code == 200) {
            this.isEdit = true;
            this.$message.success(res.msg);
          }
        });
      }
    },
    handelClick() {
      this.isEdit = false;
      this.form.mobileNum = "";
    },
    change() {},
    validatePhone(rule, value, callback) {
      if (!this.form.id) {
        let regExp = mobileRegular;
        if (!regExp.test(value)) {
          callback(new Error("您填写手机号码格式不正确"));
        } else {
          callback();
        }
      }
    },
    update(data) {
      Object.keys(this.form).forEach((key) => {
        this.form[key] = data[key];
        this.form.isPartyMember = this.form.isPartyMember
          ? String(this.form.isPartyMember)
          : "";
        this.form.isCadre = this.form.isCadre ? String(this.form.isCadre) : "";
      });
    },
    submitBefore() {
      if (this.form.id) {
        delete this.form.mobileNum;
      } else {
        this.form.mobileNum = setAESCbcEncrypt(this.form.mobileNum);
      }
      return true;
    },
    submitSuccess() {
      return true;
    },
  },
};
</script>
<style lang="less" scoped>
.partyMemberForm {
  box-sizing: border-box;
  height: 100%;
  .form-item {
    display: flex;
  }
}
</style>
<style lang="less"></style>
